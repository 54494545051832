// import coinimg1 from "assets/static/imgs/coin_gold_ronaldo.png";
// import coinimg2 from "assets/static/imgs/coin_gold_messi.png";
// import coinimg3 from "assets/static/imgs/coin_rm.png";
// import coinimg4 from "assets/static/imgs/coin_fcb.png";
// import coinimg5 from "assets/static/imgs/coin_silver_messi.png";

import ballImg1 from "assets/static/imgs/ball/1.png";
import ballImg2 from "assets/static/imgs/ball/2.png";
import ballImg3 from "assets/static/imgs/ball/3.png";
import ballImg4 from "assets/static/imgs/ball/4.png";
import ballImg5 from "assets/static/imgs/ball/5.png";
import ballImg6 from "assets/static/imgs/ball/6.png";
import ballImg7 from "assets/static/imgs/ball/7.png";

import trophyImg1 from "assets/static/imgs/trophy/1.png";
import trophyImg2 from "assets/static/imgs/trophy/2.png";
import trophyImg3 from "assets/static/imgs/trophy/3.png";
import trophyImg4 from "assets/static/imgs/trophy/4.png";
import trophyImg5 from "assets/static/imgs/trophy/5.png";
import trophyImg6 from "assets/static/imgs/trophy/6.png";
import trophyImg7 from "assets/static/imgs/trophy/7.png";
import trophyImg8 from "assets/static/imgs/trophy/8.png";
import trophyImg9 from "assets/static/imgs/trophy/9.png";
import trophyImg10 from "assets/static/imgs/trophy/10.png";
import trophyImg11 from "assets/static/imgs/trophy/11.png";
import trophyImg12 from "assets/static/imgs/trophy/12.png";
import trophyImg13 from "assets/static/imgs/trophy/13.png";

import "global.css";

function isMobileScreen() {
  return window.innerWidth <= 768; // Adjust the threshold as needed
}

function stars() {
  let e = document.createElement("img");
  // let size = Math.random() * 12;
  let duration = Math.random() * 3;
  let coinimgs = [
    // coinimg1,
    // coinimg2,
    // coinimg3,
    // coinimg4,
    // coinimg5,
    ballImg1,
    ballImg2,
    ballImg3,
    ballImg4,
    ballImg5,
    ballImg6,
    ballImg7,
    trophyImg1,
    trophyImg2,
    trophyImg3,
    trophyImg1,
    trophyImg2,
    trophyImg3,
    trophyImg9,
    trophyImg10,
    trophyImg11,
    trophyImg13,
    trophyImg4,
    trophyImg5,
    trophyImg6,
    trophyImg7,
    trophyImg8,
    trophyImg6,
    trophyImg7,
    trophyImg8,
    trophyImg12,
  ];

  let rnd = Math.floor(Math.random() * 100) % coinimgs.length;

  e.setAttribute("class", "star");
  e.setAttribute("src", coinimgs[rnd]);
  document.body.appendChild(e);

  e.style.left = Math.random() * +window.innerWidth + "px";
  e.style.width = 20 + rnd + "px";
  e.style.objectFit = "contain";
  e.style.height = 20 + rnd + "px";
  e.style.animationDuration = 4 + duration + "s";

  setTimeout(function () {
    document.body.removeChild(e);
  }, 5000);
}

export function fallingCoins() {
  // Only execute the stars function if it's not a mobile screen
  if (!isMobileScreen()) {
    setInterval(function () {
      stars();
    }, 300);
  }
}
