import { http, createConfig } from "@wagmi/core";
import { mainnet, bsc } from "@wagmi/core/chains";

export const SUBGRAPH_URL_MAIN_NET =
  "https://api.studio.thegraph.com/query/74895/r-m-coinflip/m_v0.0.1";
export const SUBGRAPH_URL_TEST_NET =
  "https://api.studio.thegraph.com/query/74895/nitecointossbsc/0.0.2";

export const bsc_mainnet = 56; // testnet;
export const sepolia_test_net = 11155111; // testnet;
export const ethereum_mainnet = 1; // mainnet;

export const ADDRESS_NULL = "0x00000000000000000000000000000000";

export const CFContractAddress_Main =
  "0x1ce1F63B584b87d0F3706bFBb2Be881270859f0B";
export const CFContractAddress_Test =
  "0xc3773d7F705B54Fd5f0c9AA4AAAd25f8249b0b69";

export const NiteTokenAddress_Main =
  "0x85F7cFe910393fB5593C65230622AA597e4223f1";
export const NiteTokenAddress_Test =
  "0x0AF2bDEfFDc7DAF9BDC14D074d29f11E754683d1";

export const config = createConfig({
  chains: [mainnet, bsc],
  transports: {
    [mainnet.id]: http(),
    [bsc.id]: http(),
  },
});
