/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useChainId, useAccount, useBalance, useReadContract } from "wagmi";
import { ethers, Contract } from "ethers";
// import {
//   Provider,
//   Signer,
//   BrowserProvider,
//   types,
//   Contract,
// } from "zksync-ethers";
import {
  extractError,
  NOT_ENOUGH_FUNDS,
  RPC_ERROR,
  USER_DENIED,
} from "lib/transactionErrors";
import { ApolloClient, gql, InMemoryCache } from "@apollo/client";
import { SUBGRAPH_URL_MAIN_NET, SUBGRAPH_URL_TEST_NET } from "config";

// images
import ImgHero from "assets/static/imgs/logo.png";
import ImgLeft from "assets/static/imgs/pepe.png";
import ImgRight from "assets/static/imgs/nite.png";
import ImgLeftCelebration from "assets/static/imgs/pepe.png";
import ImgRightCelebration from "assets/static/imgs/nite.png";
import ImgCoinLeft from "assets/static/imgs/coin_left.png";
import ImgCoinRight from "assets/static/imgs/coin_right.png";
import ImgOnFlipping from "assets/static/imgs/coin_flipping (2).gif";
import ImgHeads from "assets/static/imgs/coin_left.png";
import ImgTails from "assets/static/imgs/coin_right.png";

// header & footer
import Header from "views/Header";
import wallpaper from "assets/static/imgs/wallpaper.png";

// contract
import CoinFlipABI from "config/abis/CoinFlip.json";
import TokenABI from "config/abis/token.json";

import {
  bsc_mainnet,
  CFContractAddress_Main,
  CFContractAddress_Test,
  NiteTokenAddress_Main,
  NiteTokenAddress_Test,
  ADDRESS_NULL,
} from "config";

const Home = () => {
  const [headsOrTails, setHeadsOrTails] = useState(-1);
  const [betAmount, setBetAmount] = useState(0);
  const [curState, setCurState] = useState(0); // 0: readyToBet, 1: pending, 2: win 3: lost
  const [curBoard, switchBoard] = useState(0); // 0: leaderboard, 1: recent games

  const [playerRanking, setPlayerRanking] = useState<BettingAmountCumulative[]>(
    []
  );
  const [recentPlays, setRecentPlays] = useState<BettingResult[]>([]);
  const [isConfirmingTx, setIsConfirmingTx] = useState(false);
  const [badge, setBadge] = useState("");
  const betAmounts = [10, 15, 20, 25, 50, 100, 150, 200, 500];

  const { openConnectModal } = useConnectModal();
  const chainId = useChainId();
  const account = useAccount();

  const getCoinFlipCA = () => {
    return chainId === bsc_mainnet
      ? CFContractAddress_Test
      : CFContractAddress_Main;
  };

  const getTokenCA = () => {
    return chainId === bsc_mainnet
      ? NiteTokenAddress_Test
      : NiteTokenAddress_Main;
  };

  // wallet token balance
  const myTokenBalance = useBalance({
    address: account ? account.address! : ADDRESS_NULL,
    token: getTokenCA(),
  });

  // pool token balance
  const { data: poolBalance, refetch: refetchPoolBalance } = useReadContract({
    address: getTokenCA(),
    abi: TokenABI,
    functionName: "balanceOf",
    args: [getCoinFlipCA()],
    chainId: chainId,
  });

  // token amount by USD
  const { data: niteAmtByUSD, refetch: refetchTokenPrice } = useReadContract({
    address: getCoinFlipCA(),
    abi: CoinFlipABI,
    functionName: "getTokenByUSD",
    args: [ethers.parseEther("1")],
    chainId: chainId,
  });

  // token betAmount
  const { data: tokenBetAmount } = useReadContract({
    address: getCoinFlipCA(),
    abi: CoinFlipABI,
    functionName: "getTokenByUSDWithFee",
    args: [ethers.parseEther(betAmount.toString())],
    chainId: chainId,
  });

  // display betting result
  useEffect(() => {
    if (curState === 2) {
      document.getElementsByClassName("heads-tails-text")[0].innerHTML =
        headsOrTails ? "Nite" : "Pepe";
      document
        .getElementById("hero-image")!
        .setAttribute("src", headsOrTails ? ImgTails : ImgHeads);
    } else if (curState === 3) {
      document.getElementsByClassName("heads-tails-text")[0].innerHTML =
        headsOrTails ? "Pepe" : "Nite";
      document
        .getElementById("hero-image")!
        .setAttribute("src", headsOrTails ? ImgHeads : ImgTails);
    } else {
      // document.getElementById("hero-image")!.setAttribute("src", ImgHero)
    }
    refetchTokenPrice();
  }, [curState, headsOrTails, refetchTokenPrice]);

  useEffect(() => {
    refetchPoolBalance();
  }, [curState, headsOrTails, refetchPoolBalance]);

  // Do Betting
  const doBet = async () => {
    if (headsOrTails === -1 || betAmount < 1) {
      toast.error("Please select your guess and bet amount!");
      return;
    }

    if (myTokenBalance.data!.value < ((await tokenBetAmount) as bigint)) {
      toast.error("Insufficient token balance!");
      return;
    }

    setCurState(1);

    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();

    // const provider = new BrowserProvider(window.ethereum);
    // const signer = Signer.from(
    //   await provider.getSigner(),
    //   Number((await provider.getNetwork()).chainId),
    //   Provider.getDefaultProvider(
    //     chainId === bsc_mainnet ? types.Network.Sepolia : types.Network.Mainnet
    //   )
    // );

    const cf_contract = new Contract(getCoinFlipCA(), CoinFlipABI, signer);

    const token_contract = new Contract(getTokenCA(), TokenABI, signer);

    try {
      // get token allowance
      const allowance = await token_contract.allowance(
        account.address,
        getCoinFlipCA()
      );
      console.log("----- allowance:", allowance);

      let tokenAmount = niteAmtByUSD
        ? BigInt(niteAmtByUSD!.toString())
        : BigInt(0);
      tokenAmount = tokenAmount * BigInt(betAmount.toString());

      if (tokenAmount < 1) {
        toast.error("Invalid bet amount!");
        return;
      }

      if (Number(allowance) < tokenAmount) {
        var approveTx = await token_contract.approve(
          getCoinFlipCA(),
          (tokenAmount * BigInt(12)) / BigInt(10)
        ); // 20% slippage

        // tx return value
        const txResult = await approveTx.wait();

        console.log(txResult);
      }

      const callbackGasLimit = await cf_contract.getCallbackGasLimit();
      let gasPrice =
        (await provider.getFeeData()).gasPrice ?? BigInt(3000000000);

      console.log(
        `Current gas price: ${ethers.formatUnits(gasPrice, "gwei")} gwei`
      );

      const callbackGasFee = (
        BigInt(5) *
        callbackGasLimit *
        gasPrice
      ).toString();

      console.log("====== callbackGasLimit: ", callbackGasLimit);

      const gasEstimated = await cf_contract.requestRandomWords.estimateGas(
        callbackGasLimit,
        headsOrTails,
        ethers.parseEther(betAmount.toString()),
        true,
        { value: callbackGasFee }
      );

      console.log("====== gasEstimated", gasEstimated);

      var tx = cf_contract.requestRandomWords(
        callbackGasLimit,
        headsOrTails,
        ethers.parseEther(betAmount.toString()),
        true,
        { value: callbackGasFee, gasLimit: gasEstimated * BigInt(3) }
      );

      // display confirming image
      tx.then(async (res: any) => {
        setIsConfirmingTx(true);
      }).catch((e: any) => {});

      // tx return value
      const txResult = await (await tx).wait();

      // validate betting result
      if (txResult) {
        console.log("==== txResult", txResult);
        handleBetResult(txResult);
      }
    } catch (e) {
      // tx error handler
      console.log("--------- Error: \n", e);
      let failMsg;

      const [type] = extractError(e as any);
      switch (type) {
        case NOT_ENOUGH_FUNDS:
          failMsg =
            "There is not enough ETH in your account to send this transaction.";
          break;
        case USER_DENIED:
          failMsg = `Transaction was cancelled.`;
          break;
        case RPC_ERROR:
          failMsg =
            "Transaction failed due to RPC error. Please try changing the RPC url in your wallet settings.";
          break;
        default:
          failMsg = "Transaction failed.";
      }
      toast.error(failMsg);
      setCurState(0);
    }
  };

  const delay = async (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleBetResult = async (txResult: any) => {
    console.log("transcation returned: ", txResult);
    for (var i = 0; i < txResult!.logs.length; i++) {
      const log = txResult!.logs[i];
      if (
        log.address
          .toLowerCase()
          .localeCompare(
            chainId === bsc_mainnet
              ? CFContractAddress_Test.toLowerCase()
              : CFContractAddress_Main.toLowerCase()
          ) === 0
      ) {
        let requestID = BigInt(log.data.slice(0, 66));
        // toast.success(
        //   "Bet placed Succesfully!\nPlease wait until get betting result."
        // );

        console.log("==== requestID.toString()", requestID.toString());

        while (!(await fetchBettingResult(requestID.toString()))) {
          await delay(1000);
          console.log("----- fetching result ------");
        }

        return;
      }
    }
    toast.error(
      "Error!: Cannot decode return data.\nPlease check contract code whether it contains event."
    );
    tryAgain();
  };

  const tryAgain = () => {
    setCurState(0); // delay .3s
  };

  const fetchBettingResult = async (requestID: string) => {
    console.log("--- fetchBettingResult() ---");
    try {
      const client = new ApolloClient({
        uri:
          chainId === bsc_mainnet
            ? SUBGRAPH_URL_TEST_NET
            : SUBGRAPH_URL_MAIN_NET,
        cache: new InMemoryCache(),
      });

      const bettingResultQuery = `
        query MyQuery {
          bettingResults(where: {requestID: "${requestID}"})  {
            id
            requestID
            user
            expect
            amountUSD
            amountToken
            result
            timestamp
          }
        }
      `;
      const { data: bettingResult, error } = await client.query({
        query: gql(bettingResultQuery),
        variables: {},
        fetchPolicy: "cache-first",
      });

      if (bettingResult && !error) {
        const results: BettingResult[] = bettingResult.bettingResults;
        if (results.length > 0) {
          const betResult = results[0].result;
          const amountUSD = results[0].amountUSD;

          setIsConfirmingTx(false);

          console.log("===== betResult", betResult);
          // if success
          if (betResult) {
            toast.success(
              "Congratulations! You won " +
                parseInt(amountUSD) / 10 ** 18 +
                " USD."
            );
            setCurState(2);
          }
          // if failed
          else {
            toast.error(
              "Oops! You lost " + parseInt(amountUSD) / 10 ** 18 + " USD."
            );
            setCurState(3);
          }

          return true;
        }
      }
    } catch (error) {
      toast.error("Error while handling betting result.");
      setCurState(3);
      console.log(error);
    }

    return false;
  };

  const bettingContent = () => {
    //
    // Wallet Not Connected
    //
    if (account.isDisconnected) {
      return (
        <div className="click-to-wal-con">
          <button className="button-33" onClick={openConnectModal}>
            {" "}
            Connect Wallet
          </button>
        </div>
      );
    }

    //
    // Confirming transaction
    //
    if (isConfirmingTx) {
      return (
        <div className="modal-container">
          <div className="modal-bg"></div>
          <img src={ImgOnFlipping} alt="flipping" className="img-flipping" />
        </div>
      );
    }

    //
    // Ready to Bet
    //
    if (curState === 0) {
      return (
        <div className="flip-content">
          <div className="heads-tails">
            <button
              className={headsOrTails === 0 ? "pressed fire" : ""}
              onClick={() => setHeadsOrTails(0)}
            >
              <div className="btn-content button-cr">
                <img
                  src={ImgLeftCelebration}
                  alt="ImgLeftCelebration"
                  className="celebration"
                />
                Pepe
                <img
                  src={ImgCoinLeft}
                  alt="ImgLeftCelebration"
                  className="coin-on-btn"
                />
              </div>
            </button>
            <button
              className={headsOrTails === 1 ? "pressed fire" : ""}
              onClick={() => setHeadsOrTails(1)}
            >
              <div className="btn-content button-lm">
                <img
                  src={ImgCoinRight}
                  alt="ImgLeftCelebration"
                  className="coin-on-btn"
                />
                Nite
                <img
                  src={ImgRightCelebration}
                  alt="ImgLeftCelebration"
                  className="celebration"
                />
              </div>
            </button>
          </div>

          <p className="">FOR</p>

          <div className="usd-grid">
            {betAmounts.map((item) => {
              return (
                <button
                  className={betAmount === item ? "pressed" : ""}
                  onClick={() => setBetAmount(item)}
                >
                  <div className="btn-content">{item} USD</div>
                  <div className="shadow"></div>
                </button>
              );
            })}
          </div>

          <button className="button-82-pushable" onClick={doBet}>
            <span className="button-82-shadow"></span>
            <span className="button-82-edge"></span>
            <span className="button-82-front text">PLAY</span>
          </button>
        </div>
      );
    }

    //
    // waiting for deposit
    //
    else if (curState === 1) {
      return (
        <div className="flip-content">
          <div className="status-bg textShineBlack mt-11">
            <div className="">
              <p className="lb-1st-line">waiting for</p>
              <p className="lb-2nd-line">DEPOSIT</p>
            </div>
            <p className="lb-sm-bets">
              {headsOrTails ? "Nite" : "Pepe"} for {betAmount} USD
            </p>
          </div>
        </div>
      );
    }

    //
    // you win
    //
    else if (curState === 2) {
      return (
        <div className="flip-content">
          <div className="status-bg  ">
            <div className="mt-15 ">
              <p className="lb-win-lose  textShineBlack">YOU WIN</p>
            </div>
            <p className="lb-sm-bets textShineBlackbottom">{betAmount} USD</p>
          </div>

          <button className="btn-try-again win mt-15" onClick={tryAgain}>
            try again
          </button>
        </div>
      );
    }

    //
    // you lose
    //
    else if (curState === 3) {
      return (
        <div className="flip-content">
          <div className="status-bg textShineBlack">
            <div className="mt-15">
              <p className="lb-win-lose">YOU LOST</p>
            </div>
            <p className="lb-sm-bets">{betAmount} USD</p>
          </div>

          <button className="btn-try-again lose" onClick={tryAgain}>
            try again
          </button>
        </div>
      );
    }
  };
  // -----------------------------------------------------------------

  //
  // Subgraph for leaderboard
  //
  useEffect(() => {
    const fetchBettingHistory = async () => {
      try {
        const client = new ApolloClient({
          uri:
            chainId === bsc_mainnet
              ? SUBGRAPH_URL_TEST_NET
              : SUBGRAPH_URL_MAIN_NET,
          cache: new InMemoryCache(),
        });

        const { data: bettingResult, error } = await client.query({
          query: gql(bettingHistory),
          variables: {},
          fetchPolicy: "cache-first",
        });

        if (bettingResult && !error) {
          const ranking = bettingResult.bettingAmountCumulatives;
          const recentGames = bettingResult.bettingResults;
          setPlayerRanking(ranking);
          setRecentPlays(recentGames);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchBettingHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curState, chainId]);

  useEffect(() => {
    if (account && account.address && playerRanking.length > 0) {
      for (var i = 0; i < playerRanking.length; i++) {
        let isSame =
          playerRanking[i].id
            .toString()
            .toLowerCase()
            .localeCompare(account.address!.toString().toLowerCase()) === 0;
        if (isSame) {
          if (i === 0) {
            setBadge("You are the Top player!");
            break;
          } else if (i < 3) {
            setBadge("You are in Top 3 players!");
            break;
          } else if (i < 10) {
            setBadge("You are in Top 10 players!");
            break;
          }
        }
        setBadge("");
      }
    } else {
      setBadge("");
    }
  }, [playerRanking, account]);

  interface BettingAmountCumulative {
    id: number;
    totalAmountUSD: string;
    totalAmountToken: string;
    totalBetCount: string;
    winCount: string;
    winAmount: string;
    lostCount: string;
    lostAmount: string;
  }

  interface BettingResult {
    id: number;
    requestID: string;
    user: string;
    expect: string;
    amountUSD: string;
    amountToken: string;
    result: number;
    timestamp: string;
  }

  const bettingHistory = `
    query MyQuery {
      bettingAmountCumulatives(orderBy: totalAmountUSD, orderDirection: desc, first: 10) {
        id
        totalAmountUSD
        totalAmountToken
      }
      bettingResults(first: 10, orderBy: timestamp, orderDirection: desc) {
        id
        requestID
        user
        expect
        amountUSD
        amountToken
        result
        timestamp
      }
    }
  `;
  // -----------------------------------------------------------------

  const numberWithCommas = (x: String) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getLeaderBoard = () => {
    return (
      <div className="leaderboard">
        <table className="tb-leaderboard">
          <thead>
            <tr>
              <th>No</th>
              <th className="row-player">Player</th>
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {playerRanking.map((player, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>
                    {player.id.toString().slice(0, 6) +
                      "..." +
                      player.id.toString().slice(38, 42)}
                  </td>
                  <td>
                    {numberWithCommas(
                      Number(ethers.formatEther(player.totalAmountUSD)).toFixed(
                        0
                      )
                    ) + " USD"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const getTimeDiff = (t: String) => {
    let diff = Math.floor(Date.now() / 1000) - Number(t);
    if (diff > 3600 * 24) {
      return Math.round(diff / 3600 / 24) + " days ago";
    } else if (diff > 3600) {
      return Math.round(diff / 3600) + " hours ago";
    } else if (diff > 60) {
      return Math.round(diff / 60) + " min ago";
    } else if (diff > 0) {
      return diff + " sec ago";
    }
    return "Just now";
  };

  const getRecentGames = () => {
    return (
      <div className="leaderboard">
        <table className="tb-leaderboard">
          <thead>
            <tr>
              <th>Time</th>
              <th className="row-player">Player</th>
              <th>Amount</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            {recentPlays.map((item, i) => {
              return (
                <tr key={i}>
                  {/* <a
                    href={
                      chainId === bsc_mainnet
                        ? "https://bscscan.com/tx/" + item.id
                        : "https://etherscan.io/tx/" + item.id
                    }
                  > */}
                  <td className="col-time">{getTimeDiff(item.timestamp)}</td>
                  <td>
                    {item.user.toString().slice(0, 6) +
                      "..." +
                      item.user.toString().slice(38, 42)}
                  </td>
                  <td>
                    {numberWithCommas(
                      Number(ethers.formatEther(item.amountUSD)).toFixed(0)
                    ) + " USD"}
                  </td>
                  <td>{item.result ? "Win" : "Rugged"}</td>
                  {/* </a> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="cf-main-sec">
      <div className="hero-bg" id="hero-bg">
        <Header badge={badge} />
        <div className="cf-new-container">
          <div className="cf-hero-sec">
            <div className="pool-container">
              <div className="keep-in-pool">
                <div className="text-left">{"Home Balance: "}</div>
                <div className="text-right">
                  {poolBalance
                    ? Number(
                        ethers.formatEther(poolBalance.toString())
                      ).toFixed(2) + " NITE"
                    : "0 NITE"}
                  <br></br>
                  {poolBalance && niteAmtByUSD
                    ? "(" +
                      (Number(poolBalance) / Number(niteAmtByUSD)).toFixed(0) +
                      " USD)"
                    : "(0 USD)"}
                </div>
              </div>
            </div>
            <div
              className={
                curState === 2 || curState === 3
                  ? "heads-tails-text"
                  : "heads-tails-text hide"
              }
            ></div>
            <img
              src={ImgHero}
              className={
                curState === 2 || curState === 3
                  ? "img-hero imageglow"
                  : "img-hero hide"
              }
              alt="heroimg "
              id="hero-image"
            />
            <div
              className={
                curState === 2 || curState === 3
                  ? "main-sec-img hide"
                  : "main-sec-img"
              }
            >
              <img
                src={ImgLeft}
                className={headsOrTails === 0 ? "img-cr img-glow" : "img-cr"}
                alt="hero-cr"
              />
              <div className="div-coin">
                <img
                  src={ImgHeads}
                  className="img-coin img-coin-cr"
                  alt="hero-coin"
                />
                <img
                  src={ImgTails}
                  className="img-coin img-coin-lm"
                  alt="hero-coin"
                />
              </div>
              <img
                src={ImgRight}
                className={headsOrTails === 1 ? "img-lm img-glow" : "img-lm"}
                alt="hero-lm"
              />
            </div>

            {bettingContent()}
            <div className="board-switch">
              <button
                className={
                  curBoard === 0 ? "board-option selected" : "board-option"
                }
                onClick={() => {
                  switchBoard(0);
                }}
              >
                Leaderboard
              </button>
              <button
                className={
                  curBoard === 1 ? "board-option selected" : "board-option"
                }
                onClick={() => {
                  switchBoard(1);
                }}
              >
                Recent Games
              </button>
            </div>
            {curBoard === 0 ? getLeaderBoard() : getRecentGames()}
          </div>
        </div>
        <div className="cf-liear-bg"></div>
        <img src={wallpaper} alt="wallpaper" className="img-background" />
      </div>
    </div>
  );
};

export default Home;
