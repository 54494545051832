import { Toaster } from "react-hot-toast";
import Home from "views/Home";
import { fallingCoins } from "views/Utils/utils";

const App = () => {
  return (
    <div>
      <>{fallingCoins()}</>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "topZIndex",
          duration: 2000,
          style: {
            background: "#fff",
            color: "black",
            fontWeight: "Bold",
          },

          // Default options for specific types
          success: {
            duration: 3000,
          },
        }}
      />
      <Home />
    </div>
  );
};

export default App;
